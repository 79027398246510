<template>
  <van-popup closable="true" v-model="visible" position="bottom" :style="{ height: '30%' }">
    <div>
      <div class="title">请您进行密码验证</div>
      <van-cell class="verify">
        <van-field v-model="pwd" placeholder="请输入您的密码" />
      </van-cell>
      <van-button class="btn" round type="info" @click="verify">验证</van-button>
    </div>
  </van-popup>
</template>
<script>
export default {
  data () {
    return {
      pwd: '',
      visible: false
    }
  },
  methods: {
    verify () {
      if (this.pwd == '') {
        this.$toast.fail('请先输入密码')
        return
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/secondVerify'),
        method: 'post',
        params: this.$http.adornParams({
          pw: this.pwd
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$emit('close', true)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 40px;
  margin-top: 40px;
  color: #333333;
  text-align: center;
}
.verify {
  margin-top: 40px;
}
.verify >>> .van-field__control {
  text-align: left !important;
}
.btn {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translateX(-50%);
}
</style>
